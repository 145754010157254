<template>
  <el-popover
    width="210"
    trigger="click"
    popper-class="popoverLayers"
    placement="left"
    :visible-arrow="false"
  >
    <button
      class="transition-colors duration-300 bg-white h-9 w-9 hover_bg-reallylightblue rounded-full shadow flex items-center justify-center"
      type="button"
      slot="reference"
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 13.2145L16.36 7.80598L18 6.60724L9 0L0 6.60724L1.63 7.80598L9 13.2145ZM9 15.612L1.62 10.2035L0 11.3928L9 18L18 11.3928L16.37 10.194L9 15.612Z"
          fill="#5477A9"
        ></path>
      </svg>
    </button>
    <p class="mt-2 ml-2 mb-2" style="font-size: 14px">{{ $t('map.style') }}</p>
    <el-radio-group
      class="mapLayers flex flex col mr-3"
      v-model="layer.name"
      v-for="(item, index) in layers"
      :key="index"
      @change="selectLayer"
    >
      <el-radio class="pb-3 pt-3" :label="item.name">
        <span>
          {{ item.nameT }}
        </span>
      </el-radio>
    </el-radio-group>
    <div class="flex w-full">
      <el-divider class="mb-4 mt-4"></el-divider>
    </div>
    <div class="flex flex-col">
      <skif-checkbox
        class="layers-checkbox"
        v-for="(thing, index) in arrayOfWeather"
        :key="index"
        :label="thing"
        v-model="checklist"
      >
      </skif-checkbox>
    </div>
  </el-popover>
</template>

<script>
export default {
  data() {
    return {
      layer: {
        name: 'Yandex Map',
        src: 'yandex',
        nameT: this.$t('map.yandex')
      },

      layers: [
        {
          name: 'Yandex Map',
          src: 'yandex',
          nameT: this.$t('map.yandex')
        },

        // {
        //   name: 'Yandex Traffic',
        //   src: 'yandex-traffic',
        //   nameT: 'Yandex Traffic',
        // },

        {
          name: 'Google Sattelite',
          src: 'google-satellite',
          nameT: this.$t('map.googleSatelite')
        },
        {
          name: 'Google Traffic',
          src: 'google-traffic',
          nameT: 'Google Traffic'
        },
        {
          name: 'Google roadmap',
          src: 'google-rodmap',
          nameT: this.$t('map.googleRoadMap')
        },
        {
          name: 'Google terrain',
          src: 'google-terrain',
          nameT: this.$t('map.googleTerrain')
        },
        {
          name: 'Google hybrid',
          src: 'google-hybrid',
          nameT: this.$t('map.googleHybrid')
        },
        { name: 'OSM', src: 'OSM', nameT: this.$t('map.osm') },
        { name: 'Here', src: 'here', nameT: this.$t('map.here') },
        { name: 'Bing', src: 'bing', nameT: this.$t('map.bing') },
        {
          name: 'Bing Satellite',
          src: 'bing-satellite',
          nameT: this.$t('map.bingSatellite')
        }
      ],
      temperature: false,
      clouds: false,
      wind: false,
      checklist: [],
      arrayOfWeather: ['Temperature', 'Clouds', 'Wind'],
      myChangedValue: null
    }
  },
  watch: {
    'layer.name': function (newVal) {
      console.log(newVal)
    },
    checklist(oldVal, newVal) {
      this.myChangedValue = this.arrayDiff(oldVal, newVal)[0]
      if (oldVal.includes(this.myChangedValue)) {
        this.$emit('addOverlay', this.myChangedValue)
      } else if (newVal.includes(this.myChangedValue)) {
        this.$emit('removeOverlay', this.myChangedValue)
      }
    }
  },
  methods: {
    selectLayer(val) {
      console.log(val)
      const newLayer = this.layers.filter((el) => el.name === val)
      localStorage.setItem('lastMapLayer', this.layer.name)
      this.layer.name = newLayer[0].name
      this.layer.nameT = newLayer[0].nameT
      this.layer.src = newLayer[0].src
      this.$emit('selectLayer', this.layer.name)
    },
    arrayDiff(a, b) {
      return [
        ...a.filter((x) => !b.includes(x)),
        ...b.filter((x) => !a.includes(x))
      ]
    }
  },
  created() {
    const currentLay = localStorage.getItem('lastMapLayer')
    const newLayer = this.layers.filter((el) => el.name === currentLay)
    if (newLayer && newLayer[0]) {
      this.layer.name = newLayer[0].name
      this.layer.nameT = newLayer[0].nameT
      this.layer.src = newLayer[0].src
      this.$emit('selectLayer', this.layer.name)
    }
  }
}
</script>

<style>
.layers-checkbox {
  margin-left: 10px;
  display: flex;
  align-items: center;
  width: 40%;
  margin-bottom: 8px;
}
.layers-checkbox .skif-checkbox-label {
  color: #5477a9;
  font-size: 14px;
  font-weight: 400;
}
.popoverLayers {
  border-radius: 10px;
}
.popoverLayers .mapLayers .el-radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.popoverLayers .mapLayers .el-radio .el-radio__input {
  order: 2;
}
.popoverLayers .mapLayers .el-radio .el-radio__label {
  order: 1;
  color: #3c5e8f;
  font-weight: 700;
  font-size: 14px;
}
.popoverLayers .mapLayers .el-radio__input .el-radio__inner {
  width: 18px;
  height: 18px;
}
.popoverLayers .mapLayers .el-radio__input.is-checked .el-radio__inner {
  background: white !important;
}
.popoverLayers .mapLayers .el-radio .el-radio__input .el-radio__inner::after {
  background-color: #5477a9;
  width: 7px;
  height: 7px;
}
</style>
